import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { } from '../../graphql/types';
import { ClipboardButton } from '../common/ClipboardButton';
import { CHAT_QUESTION } from '../../graphql/chat';
import { aiLimitExcedded, errorNofification } from '../notification/notification';


export function SimpleGPTChat() {
  const [state, setState] = useState({ askId: uuid4(), question: '', answer: '', limitExcedded: false });
  const [answerQuestion, { loading, error }] = useMutation(CHAT_QUESTION, {
    onCompleted({ chat }) {
      setState({ ...state, answer: chat.text, askId: chat.id });
    }
  });
  if (error) errorNofification({ error });
  if (state.limitExcedded) aiLimitExcedded();

  return (
    <Card sx={{ minWidth: '500px' }}>
      <CardHeader title='GPT question' />
      <CardActions sx={{ ml: 1 }}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          onClick={() => {
            setState({ askId: uuid4(), question: '', answer: '', limitExcedded: false });
          }}
        >
          New question
        </LoadingButton>
        <LoadingButton variant='contained' onClick={() => {
          answerQuestion({
            variables: {
              askId: state.askId,
              question: state.question
            }
          });
        }}
          loading={loading}
          disabled={state.question === ''}
          sx={{ width: '150px' }}
        >
          Answer
        </LoadingButton>
      </CardActions>
      <CardContent>
        <Stack spacing={2}
        >
          <TextField
            label='Question'
            disabled={loading}
            multiline
            minRows={6}
            placeholder='Question?'
            onChange={(newValue) => {
              setState({ ...state, question: newValue.target.value, answer: '' });
            }}
            InputProps={{
              endAdornment: (<InputAdornment position="end">
                <ClipboardButton text={state.question} />
              </InputAdornment>)
            }}
          />
          <TextField
            label='Answer'
            InputProps={{
              readOnly: true,
              endAdornment: (<InputAdornment position="end">
                <ClipboardButton text={state.answer} />
              </InputAdornment>)
            }}
            disabled={loading}
            multiline
            minRows={6}
            placeholder="answer here ..."
            value={state.answer}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

