import { Stack } from '@mui/system';
import { SimpleGPTChat } from '../chat/SimpleGPTQuestion';
import { TitleQuestion } from '../chat/TitleQuestion';

export function Comparison() {
  return (
    <Stack spacing={2}
      sx={{ margin: '10px', width: '100%' }}
      direction={'row'}>
      <TitleQuestion />
      <SimpleGPTChat />
    </Stack>
  );
}
