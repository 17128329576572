import React, { useEffect } from "react";

import {
  Button,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MuiMarkdown, getOverrides } from "mui-markdown";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export type MarkDown = string; // in MakkDown format
interface PolicyDialogProps {
  open: boolean;
  title: string;
  contentPath: MarkDown;
  onClose?: () => void;
  showAgree?: boolean;
  onAgree?: () => void;
  onDisagree?: () => void;
}
export default function PolicyDialog({
  contentPath,
  title,
  open,
  onClose,
  showAgree,
  onAgree,
  onDisagree,
}: PolicyDialogProps) {
  function handleClose() {
    if (onClose) onClose();
  }
  function handleAgree() {
    handleClose();
    if (onAgree) onAgree();
  }
  function handleDisagree() {
    handleClose();
    if (onDisagree) onDisagree();
  }

  const [content, setContent] = React.useState<string>("");

  useEffect(() => {
    fetch(contentPath)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      });
  }, []);
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="policy-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="policy-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <DialogContent dividers>
          <MuiMarkdown
            overrides={{
              ...getOverrides({}),
              h1: {
                component: Typography,
                props: {
                  variant: "h5",
                },
              },
              h2: {
                component: Typography,
                props: {
                  variant: "h6",
                },
              },
            }}
          >
            {content || ""}
          </MuiMarkdown>
        </DialogContent>
        {showAgree && (
          <DialogActions>
            <Button autoFocus onClick={handleAgree}>
              Agree
            </Button>
            <Button onClick={handleDisagree}>Disagree</Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </React.Fragment>
  );
}
