import { useQuery } from "@apollo/client";
import { Box, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router";
import { SynonymsQuery, SynonymsQueryVariables } from "../../../graphql/types";
import { LoadingButton } from "@mui/lab";
import SynonymDetails from "./SynonymDetails";
import { LIST_SYNONYMS } from "../../../graphql/synonym";
import { errorNofification } from "../../notification/notification";

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 200, },
  {
    field: "common",
    headerName: "Common",
    minWidth: 300,
  },

  {
    field: "legal",
    headerName: "Legal",
    minWidth: 600,
  },
];

export default function SynonymList() {
  const navigate = useNavigate();
  const params = useParams();
  const { data, loading, error } = useQuery<
    SynonymsQuery,
    SynonymsQueryVariables
  >(LIST_SYNONYMS);
  if (error) errorNofification({ error });

  function handleRowClick(params: GridRowParams) {
    const synonym = params.row;
    console.log("clicked synonym:", synonym);
    navigate(`${synonym.id}`);
  }
  return (
    <Card sx={{ margin: "10px", minWidth: "500px" }}>
      <CardHeader title="Synonyms" />
      <CardActions sx={{ ml: 1 }}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("new");
          }}
        >
          New
        </LoadingButton>
      </CardActions>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            autoHeight
            sx={{ minHeight: "20em" }}
            density="compact"
            rows={data?.synonyms || []}
            loading={loading}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
          />
        </Box>
      </CardContent>
      {params.synonymId && <SynonymDetails />}
    </Card>
  );
}
