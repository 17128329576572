import { SignedIn, useUser } from "@clerk/clerk-react";
import {
  Avatar,
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { MeQuery, MeQueryVariables } from "../../../graphql/types";
import { ME } from "../../../graphql/user";
import { errorNofification } from "../../notification/notification";
import { UserDrawer } from "./UserDraw";
import { stripeCustomerId } from "../../common/variables";

export default function UserIdentity() {
  const { user, isSignedIn } = useUser();

  const [isOpen, setOpen] = useState(false);
  const { data: meData, error: meError } = useQuery<MeQuery, MeQueryVariables>(
    ME,
    {
      fetchPolicy: "cache-first",
      skip: !isSignedIn,
    }
  );

  if (meError) errorNofification({ error: meError });
  if (meData?.me?.customer) {
    stripeCustomerId(meData.me.customer.id as string);
  }

  function handleUserClick() {
    // console.log("handleUserClick");
    setOpen(true);
  }

  return (
    <Box sx={{ margin: "1em", color: "white" }}>
      <SignedIn>
        <Tooltip title="User">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            alignItems="center"
            justifyContent="center"
          >
            {user ? (
              <Avatar
                alt={user?.fullName || ""}
                src={user?.imageUrl}
                onClick={handleUserClick}
              />
            ) : (
              <Skeleton variant="circular" />
            )}
            <Typography variant="body2">
              {user ? user.fullName : <Skeleton />}
            </Typography>
          </Stack>
        </Tooltip>

        <UserDrawer
          customerId={
            meData?.me?.customer ? (meData.me.customer.id as string) : ""
          }
          open={isOpen}
          imageUrl={user?.imageUrl}
          onClose={(event, reason) => {
            setOpen(false);
          }}
        />
      </SignedIn>
    </Box>
  );
}
