import { Box, Card, CardHeader, Tooltip, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// TODO fix hard coded colours
/**
 * Determines the color of the chip based on the type.
 * @param type - The type of the reference.
 * @returns The color of the chip.
 */
function chipColour(type: string) {
  switch (type) {
    case "procedural":
      return "primary";
    case "rate":
      return "secondary";
    default:
      return "info";
  }
}

/**
 * Renders a chip component for a reference.
 * @param props - The component props.
 * @param props.id - The optional ID of the chip.
 * @param props.reference - The reference object.
 * @param props.onclick - The optional click event handler for the chip.
 * @returns The rendered chip component.
 */
export function ReferenceChip(props: {
  reference: any;
  onclick?: (reference: any) => void;
}) {
  /**
   * Handles the click event of the chip.
   */
  function handleChipClick() {
    if (props.onclick) props.onclick(props.reference);
    else window.open(props.reference.source, "_blank");
  }

  return (
    <Tooltip title={props.reference.source} placement="top">
      <Chip
        label={props.reference.id}
        size="small"
        // key={props.k}
        id={props.reference.id}
        color={chipColour(props.reference.type)}
        onClick={handleChipClick}
      />
    </Tooltip>
  );
}

/**
 * Renders a list of reference chips.
 * @param props - The component props.
 * @param props.references - The array of reference objects.
 * @param props.onReferenceClick - The optional click event handler for the reference chips.
 * @returns The rendered list of reference chips.
 */
export function References(props: {
  references: any[];
  onReferenceClick?: (reference: any) => void;
}) {
  return (
    <Box>
      <Typography variant="caption">References:</Typography>
      <Stack direction="row" spacing={1} style={{ justifyContent: "left" }}>
        {props.references.map((ref: any, index: number) => {
          return (
            <ReferenceChip
              key={index.toString() + ref.id}
              reference={ref}
              onclick={props.onReferenceClick}
            />
          );
        })}
      </Stack>
    </Box>
  );
}
