import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Md5 } from "ts-md5";
import { v4 as uuid4 } from "uuid";
import {
  Stack,
  TextField,
  CardActions,
  Card,
  CardContent,
  InputAdornment,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  MeQuery,
  MeQueryVariables,
  QuestionAnswerRating,
  ReferenceDocument,
} from "../../graphql/types";

import { RateQuestion } from "../admin/rating/RateAnswer";
import { ClipboardButton } from "../common/ClipboardButton";
import { References } from "./References";
import { QUESTION_ASK, RECENT_QUESTION_ANSWER } from "../../graphql/chat";
import { Protect } from "@clerk/clerk-react";
import { AnswerMutation, AnswerMutationVariables } from "../../graphql/types";
import { QuestionRecentsList } from "./Recents";
import { checkAndProcessAiLinit, formatReferences } from "./utils";
import SendIcon from "@mui/icons-material/Send";
// import { USAGE } from '../../graphql/user';
import { errorNofification } from "../notification/notification";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import SubscribeDialog from "../subscriptions/SubscribeDialog";
import { ME } from "../../graphql/user";
import TermsOfService from "../admin/legal/TermsOfService";
import { useElectraOrganisation } from "../auth/OrgHook";

type QuestionAnswerReferences = {
  question: string;
  answer: string;
  references: ReferenceDocument[];
  askId: string;
  withSynonym: boolean;
  minSimilarity?: number;
};

export function TitleQuestion() {
  const [askId, setAskId] = useState(uuid4());
  const [termsOpen, setTermsOpen] = useState(false);
  const { data: orgData } = useElectraOrganisation();
  const { data: meData, error: meError } = useQuery<MeQuery, MeQueryVariables>(
    ME,
    {
      fetchPolicy: "cache-first",
    }
  );
  useEffect(() => {
    if (meData) {
      console.log("[TitleQuestion] meData", meData);
      if (!meData.me?.agreedToTermsAndConditions) {
        setTermsOpen(true);
      }
    }
  }, [meData]);
  // const [streamedAnswer, setStreamedAnswer] = useState<string>('');
  const [state, setState] = useState<QuestionAnswerReferences>({
    question: "",
    answer: "",
    references: [],
    askId: "",
    withSynonym: true,
  });
  // const [askButtonText, setAskButtonText] = useState('Ask');

  const [
    answerQuestion,
    { data, loading: questionAnswerLoading, error: questionAnswerError },
  ] = useMutation<AnswerMutation, AnswerMutationVariables>(QUESTION_ASK, {
    refetchQueries: [RECENT_QUESTION_ANSWER],
  });

  // const { data: streamData, loading: streamLoading, error: streamError } = useSubscription<AnswerStreamSubscription, AnswerStreamSubscriptionVariables>(ANSWER_STREAM, {
  //   variables: {
  //     askId: askId
  //   },

  //   onData: (event) => {
  //     console.log('[TitleQuestion.onSubscriptionData] streamData +', event.data.data);
  //     const answer: any = event.data.data;
  //     setStreamedAnswer(streamedAnswer + answer.answerStream.text as string);
  //   },
  //   shouldResubscribe: true,
  //   onError: (error) => {
  //     console.log('[TitleQuestion] streamError', error);
  //   },
  //   onComplete: () => {
  //     console.log('[TitleQuestion] streamComplete');
  //   }
  // });

  useEffect(() => {
    if (data) {
      setState({
        ...state,
        answer: data.answer.text,
        references: data.answer.references
          ? data.answer.references.filter(
              (ref: any): ref is ReferenceDocument => ref !== null
            )
          : [],
      });
    }
    // setAskButtonText('Ask');
  }, [data]);

  if (meError) {
    errorNofification({ error: meError });
  }

  function reAskQuestion(old: QuestionAnswerRating) {
    const oldQuestion = old.question as string;
    const oldAnswer = old.answer as string;
    const oldReferences = old.references
      ? old.references.filter(
          (ref: any): ref is ReferenceDocument => ref !== null
        )
      : [];
    setState({
      question: oldQuestion as string,
      answer: oldAnswer,
      references: oldReferences,
      askId: "",
      withSynonym: state.withSynonym,
    });
    // setAskButtonText('Re-Ask');
  }
  function newQuestion() {
    setState({
      question: "",
      answer: "",
      references: [],
      askId: "",
      withSynonym: state.withSynonym,
    });
  }

  function askQuestion() {
    const askId = uuid4();
    setAskId(askId);
    answerQuestion({
      variables: {
        askId: askId,
        questionId: Md5.hashAsciiStr(state.question),
        question: state.question,
        withSynonym: state.withSynonym,
        minimumSimalarity: 0.8,
      },
      refetchQueries: [
        // USAGE,
        RECENT_QUESTION_ANSWER,
      ],
      errorPolicy: "all",
    });
  }
  if (questionAnswerError) {
    if (checkAndProcessAiLinit(questionAnswerError)) {
      return <SubscribeDialog open={true} />;
    } else {
      errorNofification({ error: questionAnswerError });
    }
  }
  // console.log('[TitleQuestion] askId', askId);
  // console.log('[TitleQuestion] streamData', streamData);

  return (
    <Card sx={{ minWidth: "500px" }}>
      <CardActions sx={{ ml: 1 }}>
        <Button
          disabled={questionAnswerLoading}
          variant="outlined"
          color="primary"
          onClick={newQuestion}
        >
          New question
        </Button>

        <Protect permission={"org:ui:debug"}>
          <FormControlLabel
            sx={{ ml: "1em" }}
            control={
              <Checkbox
                onChange={(event) => {
                  setState({ ...state, withSynonym: event.target.checked });
                }}
                checked={state.withSynonym}
              />
            }
            label="with Synonym"
          />
        </Protect>
      </CardActions>
      <CardContent sx={{ width: "100%" }}>
        <Stack direction="row" spacing={2}>
          <QuestionRecentsList reAsk={reAskQuestion} />
          <Stack direction="row" spacing={2}>
            <Stack spacing={2}>
              <TextField
                label="Question"
                disabled={questionAnswerLoading}
                multiline
                minRows={7}
                placeholder="Question?"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingButton
                        sx={{ mt: 2 }}
                        color="primary"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={askQuestion}
                        disabled={state.question === ""}
                        loading={questionAnswerLoading}
                      />
                    </InputAdornment>
                  ),
                }}
                value={state.question}
                onChange={(newValue) => {
                  setState({ ...state, question: newValue.target.value });
                }}
              />
              <Box
                sx={{
                  display: "flex",

                  border: 1,
                  padding: "1em",
                  minHeight: "12em",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <AnswerBox>{state.answer || ""}</AnswerBox>
                  {state.answer && state.answer !== "" && (
                    <ClipboardButton
                      text={`Question:\n${state.question}\n\nAnswer:\n${
                        state.answer
                      }\n\nReferences:\n${formatReferences(state.references)}`}
                    />
                  )}
                </Stack>
              </Box>
              <References references={state.references} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Elementary can make mistakes. Check the references for
                comprehensive information.
              </Typography>

              {orgData?.role === "org:admin" && (
                <RateQuestion
                  askId={askId}
                  disabled={!data || questionAnswerLoading}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <TermsOfService open={termsOpen} onClose={() => setTermsOpen(false)} />
      </CardContent>
    </Card>
  );
}

interface CustomTypographyProps {
  variant: "h1" | "h2" | "h3" | "body1";
  children: React.ReactNode;
}

// Custom Typography component
const CustomTypography: React.FC<CustomTypographyProps> = ({
  variant,
  children,
}) => {
  return <Typography variant={variant}>{children}</Typography>;
};

export function AnswerBox({ children }: { children?: string | null }) {
  return (
    <MuiMarkdown
      overrides={{
        ...getOverrides({}), // This will keep the other default overrides.
        h1: { component: CustomTypography, props: { variant: "h2" } },
        h2: { component: CustomTypography, props: { variant: "h3" } },
        h3: { component: CustomTypography, props: { variant: "h4" } },
        h4: { component: CustomTypography, props: { variant: "h5" } },
        h5: { component: CustomTypography, props: { variant: "h6" } },
        p: { component: CustomTypography, props: { variant: "body1" } },
      }}
    >
      {children}
    </MuiMarkdown>
  );
}
