import React, { useId, useMemo } from "react";
import {
  RateTestResultMutation,
  RateTestResultMutationVariables,
  Rating,
  Source,
  SourceRelevanceMutation,
  SourceRelevanceMutationVariables,
  TestResult,
} from "../../../../graphql/types";
import { Divider, List, ListItem, Stack, Typography } from "@mui/material";
import { RateAnswer } from "../RateAnswer";
import { useMutation } from "@apollo/client";
import { RateReference } from "../RateReference";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import ReactJson from "react-json-view";
import {
  RATE_TEST_RESULT,
  SOURCE_RELEVANCE,
  TEST_DETAILS,
} from "../../../../graphql/ratings";

export interface TestResultListProps {
  testSuiteId: string;
  loading?: boolean;
  tests: TestResult[];
}
export function TestResultList(props: TestResultListProps) {
  const results = useMemo(() => {
    const views = props.tests.map((t: TestResult, index: number) => {
      const view = {
        id: index,
        question: t.question,
        answer: t.answer,
        rating: t.rating,
      };
      return view;
    });
    return views;
  }, [props.tests]);

  console.log("[TestResultList] results", results);

  return (
    <div>
      <Typography variant="h6">Results</Typography>
      <List
        sx={{
          width: "auto",
          // maxWidth: 360,
          // bgcolor: 'background.paper',
          position: "relative",
          overflow: "auto",
          height: "800px",
          "& ol": { padding: 0 },
        }}
        subheader={<li />}
      >
        {props.tests.map((result: TestResult, index: number) => {
          return (
            <TestResultAction
              testSuiteId={props.testSuiteId}
              result={result}
              index={index}
            />
          );
        })}
      </List>
    </div>
  );
}

export function TestResultAction(props: {
  testSuiteId: string;
  result: TestResult;
  index: number;
}) {
  const id = useId();
  const [rating, setRating] = React.useState<Rating>(
    props.result.rating || Rating.Unrated
  );
  const [saveRating, { data, loading, error }] = useMutation<
    RateTestResultMutation,
    RateTestResultMutationVariables
  >(RATE_TEST_RESULT, {
    refetchQueries: [TEST_DETAILS],
  });
  const [
    saveSourceRelevance,
    { data: sourceData, loading: sourceLoading, error: sourceError },
  ] = useMutation<SourceRelevanceMutation, SourceRelevanceMutationVariables>(
    SOURCE_RELEVANCE,
    {
      refetchQueries: [TEST_DETAILS],
    }
  );

  console.log("[TestResultAction] result", props.result);

  const sources = props.result.sources || [];

  return (
    <ListItem>
      <Stack>
        <Typography variant="h6">Question</Typography>
        <p>{props.result.question}</p>
        <Typography variant="h6">Answer</Typography>
        <p>{props.result.answer}</p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1ch-content"
            id="panel1ch-header"
          >
            <Typography variant="h6" sx={{ width: "33%", flexShrink: 0 }}>
              Synonym question
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{props.result.synonymQuestion}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h6" sx={{ width: "33%", flexShrink: 0 }}>
              Prompt
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props.result.prompt && (
              <ReactJson src={JSON.parse(props.result.prompt)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Typography variant="h6">Rating</Typography>

        <Stack direction="row" spacing={2}>
          <RateAnswer
            rating={rating}
            onRated={(rating: Rating) => {
              setRating(rating);
              console.log(props.testSuiteId, props.index, rating);
              saveRating({
                variables: {
                  testSuiteId: props.testSuiteId,
                  testIndex: props.index,
                  rating: rating,
                },
              });
            }}
          />
          <Stack spacing={1} style={{ justifyContent: "left" }}>
            {sources.map((ref: any, index: number) => {
              return (
                <RateReference
                  id={id + index.toString()}
                  reference={ref}
                  onReferenceChange={(ref: any) => {
                    saveSourceRelevance({
                      variables: {
                        testSuiteId: props.testSuiteId,
                        testIndex: props.index,
                        sourceId: ref.id,
                        relevance: ref.relevance,
                      },
                    });
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
        <Divider sx={{ mt: "1rem" }} />
      </Stack>
    </ListItem>
  );
}
