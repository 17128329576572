import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { DataGrid, GridPaginationModel, GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";

import { User } from "../../../graphql/types";
import { useNavigate, useParams } from "react-router";
import { UserDetails } from "./UserDetails";
import { USER_LIST } from "../../../graphql/user";
import { errorNofification } from "../../notification/notification";
import { userColumns } from "./utils";
import React, { useMemo, useState } from "react";

export function UserList() {
  const navigate = useNavigate();
  const params = useParams();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const { data, loading, error, fetchMore } = useQuery(USER_LIST);
  if (error) errorNofification({ error });

  function handleChangePage(event: unknown, newPage: number) {
    // setOffset(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    // setLimit(+event.target.value);
    // setOffset(0);
  }

  function handleRowClick(params: GridRowParams) {
    const user = params.row as User;
    console.log("clicked user:", user);
    navigate(`${user.id}`);
  }

  console.log("users:", data);

  return (
    <Card sx={{ margin: "10px", minWidth: "500px" }}>
      <CardHeader title="Users" />
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            autoHeight
            sx={{ minHeight: "20em" }}
            density="compact"
            rows={data ? data.users : []}
            loading={loading}
            columns={userColumns}
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel: GridPaginationModel) => {
              // setOffset(newModel.page);
              // setLimit(newModel.pageSize);
              // fetchMore({variables: {
              //   offset,
              //   limit,
              // }});
            }}
            initialState={{
              pagination: {
                paginationModel: paginationModel,
              },
            }}
            pageSizeOptions={[10, 20, 50]}
          />
        </Box>
      </CardContent>
      {params.userId && <UserDetails />}
    </Card>
  );
}
