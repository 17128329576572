import { useQuery } from "@apollo/client";
import {
  Card, CardHeader,
  CardActions, InputLabel, CardContent,
  Select, FormControl, MenuItem
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router";
import { TestListQuery, TestListQueryVariables, Rating } from "../../../../graphql/types";
import { TestSuiteDetails } from "./TestSuiteDetails";
import { useState } from "react";
import { TEST_LIST } from "../../../../graphql/ratings";



const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 200, },
  {
    field: 'name',
    headerName: 'Test Suite Name',
    minWidth: 400,
  },

  {
    field: 'timestamp',
    headerName: 'When',
    minWidth: 150,
  },
];




export function TestSuiteList() {
  const navigate = useNavigate();
  const params = useParams();
  const [ratingFilter, setRatingFilter] = useState<Rating[]>([Rating.Unrated]);
  const { data, loading, error } = useQuery<TestListQuery, TestListQueryVariables>(TEST_LIST,
    { variables: { rating: ratingFilter } });

  function handleRatingFilterChange(rating: Rating) {
    setRatingFilter([rating]); //TODO handle multiple ratings
  }

  if (error) {
    console.error('error:', error);
  }

  function handleRowClick(params: GridRowParams) {
    const test = params.row;
    console.log('clicked rating:', test);
    navigate(`${test.id}`);
  }
  console.log('data', data);

  return (
    <Card sx={{ margin: '10px', minWidth: '500px' }}>
      <CardHeader title='Test Suites' />
      <CardActions>
        <SelectRating default={ratingFilter[0]} onChange={handleRatingFilterChange} />
      </CardActions>
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight
            sx={{ minHeight: '20em' }}
            density='compact'
            rows={(data) ? data.testSuits : []}
            loading={loading}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
          />
        </Box>
      </CardContent>
      {params.testSuiteId && <TestSuiteDetails />}
    </Card >
  );
}

export function SelectRating(props: { default?: Rating, onChange?: (rating: Rating) => void; }) {
  function onChange(event: any) {
    if (event !== null && event.target.value !== null && props.onChange) props.onChange(event.target.value as Rating);
  }
  return (
    <FormControl sx={{ width: 150 }}>
      <InputLabel id="rating-type-select-filter-label">Rating type</InputLabel>
      <Select
        // variant="standard"
        labelId="rating-type-select-filter-label"
        id="rating-type-select-filter"
        value={props.default || Rating.Unrated}
        label="Rating"
        onChange={onChange}
      >
        <MenuItem value={Rating.Unrated}>Un-rated</MenuItem>
        <MenuItem value={Rating.Bad}>Bad</MenuItem>
        <MenuItem value={Rating.Neutral}>Neutral</MenuItem>
        <MenuItem value={Rating.Good}>Good</MenuItem>
      </Select>
    </FormControl>
  );
}