import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  AddSynonymMutation,
  AddSynonymMutationVariables,
  RemoveSynonymMutation,
  RemoveSynonymMutationVariables,
  Synonym,
  SynonymQuery,
  SynonymQueryVariables,
  UpdateSynonymMutation,
  UpdateSynonymMutationVariables,
} from "../../../graphql/types";
import { useMutation, useQuery } from "@apollo/client";
import {
  SYNONYM,
  ADD_SYNONYM,
  LIST_SYNONYMS,
  UPDATE_SYNONYM,
  DELETE_SYNONYM,
} from "../../../graphql/synonym";
import {
  errorNofification,
  infoNotification,
  warningNofification,
} from "../../notification/notification";
import LoadingTextField from "../../common/LoadingTextField";

export default function SynonymDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const synonymId = useMemo(() => {
    return params.synonymId || "new";
  }, [params.synonymId]);
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<SynonymQuery, SynonymQueryVariables>(SYNONYM, {
    variables: {
      synonymId: synonymId,
    },
    skip: synonymId === "new",
    onCompleted: (data) => {
      if (data.synonym) setSynonym(data.synonym);
    },
  });
  if (queryError) errorNofification({ error: queryError });
  const [synonym, setSynonym] = useState<Synonym>({ common: "", legal: "" });
  const [addSynonym, { data, loading: addLoading, error: addError }] =
    useMutation<AddSynonymMutation, AddSynonymMutationVariables>(ADD_SYNONYM, {
      variables: {
        newSynonym: {
          common: synonym.common as string,
          legal: synonym.legal as string,
        },
      },
      refetchQueries: [LIST_SYNONYMS],
      onCompleted: (data) => {
        infoNotification({
          message: `Synonym ${data.addSynonym?.common} added`,
        });
        navigate(-1);
      },
    });

  if (addError) errorNofification({ error: addError });

  const [
    updateSynonym,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation<UpdateSynonymMutation, UpdateSynonymMutationVariables>(
    UPDATE_SYNONYM,
    {
      variables: {
        synonymId: synonymId,
        updatedSynonym: {
          legal: synonym.legal as string,
        },
      },
      refetchQueries: [LIST_SYNONYMS],
      onCompleted: (data) => {
        infoNotification({
          message: `Synonym ${data.updateSynonym?.common} updated`,
        });
        navigate(-1);
      },
    }
  );
  if (updateError) errorNofification({ error: updateError });

  const [
    deleteSynonym,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation<RemoveSynonymMutation, RemoveSynonymMutationVariables>(
    DELETE_SYNONYM,
    {
      variables: {
        synonymId: synonymId,
      },
      refetchQueries: [LIST_SYNONYMS],
      onCompleted: (data) => {
        warningNofification({
          message: `Synonym ${data.removeSynonym?.common} deleted`,
        });
        navigate(-1);
      },
    }
  );

  if (deleteError) errorNofification({ error: deleteError });

  function onButtonClick() {
    if (synonymId == "new") {
      addSynonym();
    } else {
      updateSynonym();
    }
  }

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={() => navigate(-1)}
      SlideProps={{ direction: "left" }}
    >
      <Card sx={{ margin: "10px", minWidth: "600px" }}>
        <CardHeader title="Synonym" />

        <CardActions sx={{ ml: 1 }}>
          <LoadingButton
            loading={addLoading || updateLoading || queryLoading}
            variant="contained"
            color="primary"
            onClick={onButtonClick}
          >
            {synonymId == "new" ? "Add" : "Save"}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            loading={addLoading || updateLoading || queryLoading}
            disabled={synonymId == "new"}
            sx={{ width: "150px", color: "red" }}
            onClick={() => {
              deleteSynonym();
            }}
          >
            Delete
          </LoadingButton>
        </CardActions>
        <CardContent>
          <Stack spacing={2}>
            <LoadingTextField
              id="commonTerm"
              label="Common"
              variant="outlined"
              fullWidth
              value={synonym.common}
              InputProps={{
                readOnly: synonymId != "new",
              }}
              onChange={(e: any) => {
                setSynonym({
                  ...synonym,
                  common: e.target.value,
                });
              }}
              loading={addLoading || updateLoading || queryLoading}
            />

            <LoadingTextField
              id="legalTerm"
              label="Legal"
              variant="outlined"
              fullWidth
              value={synonym.legal}
              onChange={(e: any) => {
                setSynonym({
                  ...synonym,
                  legal: e.target.value,
                });
              }}
              loading={addLoading || updateLoading || queryLoading}
            />
            <Typography variant="body1">
              Created at: {queryData?.synonym?.createdAt?.toString() || ""}
            </Typography>
            <Typography variant="body1">
              Updated at: {queryData?.synonym?.updatedAt?.toString() || ""}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Drawer>
  );
}
