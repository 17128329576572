import { useLazyQuery, useQuery } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import { useParams, useNavigate } from "react-router";
import { USER_DETAILS } from "../../../graphql/user";
import { Divider, Grid } from "@mui/material";
import {
  User,
  UserActivityQuery,
  UserActivityQueryVariables,
  UserDetailsQuery,
  UserDetailsQueryVariables,
} from "../../../graphql/types";
import { ActivityDataGrid } from "../../chat/Recents";
import { CustomerCard } from "../customer/CustomerCard";
import { errorNofification } from "../../notification/notification";
import { UserCard } from "./UserCard";
import { USER_ACTIVITY } from "../../../graphql/chat";

export function UserDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    data: userData,
    loading: loadinUser,
    error: errorUser,
  } = useQuery<UserDetailsQuery, UserDetailsQueryVariables>(USER_DETAILS, {
    variables: {
      id: params.userId as string,
    },
  });
  const [
    userActivity,
    { data: activity, loading: activityLoading, error: activityError },
  ] = useLazyQuery<UserActivityQuery, UserActivityQueryVariables>(
    USER_ACTIVITY,
    {
      variables: {
        userId: params.userId as string,
        days: 365,
      },
    }
  );
  if (errorUser) errorNofification({ error: errorUser });
  if (activityError) errorNofification({ error: activityError });

  if (userData) console.log("[UserDetails] userData", userData);

  console.log("activity", activity);
  return (
    <Drawer anchor="right" open={true} onClose={() => navigate(-1)}>
      <Grid container sx={{ overflowY: "scroll" }}>
        <Card sx={{ margin: "10px", minWidth: "500px", maxWidth: "650px" }}>
          <CardHeader title="User Details" />
          <CardContent>
            <Stack spacing={2}>
              <Divider textAlign="left">Clerk</Divider>
              <UserCard user={userData?.user as User} loading={loadinUser} />
              <Divider textAlign="left">Stripe</Divider>
              {userData?.user?.customer && (
                <CustomerCard
                  customerId={userData.user.customer.id as string}
                  userImageUrl={userData.user.imageUrl}
                />
              )}

              {userData && (
                <ActivityDataGrid userId={userData?.user?.id as string} />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Drawer>
  );
}
